import "./Dashboard.css";
import SideBar from "../../components/SideBar/SideBar";
import { Outlet } from "react-router-dom";
import { Grid } from "@mui/material";

const Dashboard = () => {
  return (
    <Grid container>
      <Grid item md={2}>
      <SideBar />
      </Grid>
      
      <Grid item md={10}>
        <Outlet />
      </Grid>
    </Grid>
  );
};

export default Dashboard;
