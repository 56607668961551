import { Alert, AlertTitle, Button, Input, Stack } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";
import { api_url } from "../../core/Config";
import AddNewUser from "../AddNewUser/AddNewUser";
import './UsersList.css';
const UsersList = () => {
  const [records, setRecords] = useState([]);
  const [users, setUsers] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [reloadPage, setReloadPage] = useState(false);
  const usersList = async () => {
    const jwt = await localStorage.getItem("token");
    axios
      .get(
        `${api_url}/users/allusers`,

        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      )
      .then((response) => {
        setUsers(response.data);
        setRecords(response.data);
        console.log("response : ", response.data);
      })
      .catch((error: any) => {
        alert(error); 
      });
  };
  useEffect(() => {
    usersList();
  }, []);
  useEffect(() => {
    if (reloadPage) {
      setTimeout(() => {
        window.location.reload();
      }, 900);
    }
  }, [reloadPage]);
  const desactivateUser = async (id: number) => {
    const jwt = await localStorage.getItem("token");
   // console.log("token desactiver : ", jwt);
    axios
      .post(
        `${api_url}/users/desactiver/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      )
      .then((response) => {
  
        setSuccessMessage("L'utilisateur a été désactivé avec succès");
     
        setReloadPage(true);
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  };

  const resetUser = (id: number) => {
    const jwt = localStorage.getItem("token");
    console.log("token reset : ", jwt);
    axios
      .post(
        `${api_url}/users/reset/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      )
      .then((response) => {
        setSuccessMessage("L'utilisateur a été réinitialisé avec succès");
        /*setTimeout(() => {
          setSuccessMessage('');
        },54000); */
        setReloadPage(true);
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  };

  const tableCustomStyles = {
    headRow: {
      style: {
        color: "#223336",
        backgroundColor: "#e7eef0",
      },
    },
    rows: {
      style: {
        color: "white",
        backgroundColor: "#cc0066",
      },
      stripedStyle: {
        color: "NORMALCOLOR",
        backgroundColor: "NORMALCOLOR",
      },
    },
  };

  const columns = [
    {
      name: "Email",
      selector: (row: any) => row.email,
      width: "300px",
    },
    {
      name: "Matricule",
      selector: (row: any) => row.matricule,
    },
    {
      name: "Nom",
      selector: (row: any) => row.firstName,
    },
    {
      name: "Prénom",
      selector: (row: any) => row.lastName,
    },
    {
      name: "Genre",
      selector: (row: any) => row.genre,
    },
    {
      name: "Téléphone",
      selector: (row: any) => row.phoneNumber,
      width: "200px",
    },
    {
      name: "Flag",
      selector: (row: any) => row.flag,
    },
    {
      name: "Actions",
      selector: (row: any) => {
        return (
          <>
            <Button
            
              variant="contained"
              style={{ backgroundColor: "#f9b719" }}
              key={row.id}
              onClick={() => desactivateUser(row.id)}
            >
              Désactiver
            </Button>
            <Button
              variant="contained"
              key={row.email}
              onClick={() => resetUser(row.id)}
            >
              Reset password
            </Button>
          </>
        );
      },
      width: "400px",
    },
  ];
  function handleFilter(event: any) {
    if (event.target.value !== "") {
      const newData = users.filter((row: any) => {
        return row.matricule
          .toLowerCase()
          .includes(event.target.value.toLowerCase());
      });
      setRecords(newData);
    } else {
      setRecords(users);
    }
  }
 /* function handleFilterDate(event: any) {
    console.log("date de search = ",event.target.value)
    // if (event.target.value !== "") {
    //   const newData = users.filter((row: any) => {
    //     return row.matricule
    //       .toLowerCase()
    //       .includes(event.target.value.toLowerCase());
    //   });
    //   setRecords(newData);
    // } else {
    //   setRecords(users);
    // }
  }*/
  return (
    <div className="">
      {successMessage && (
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert variant="outlined" severity="success">
        <AlertTitle>Success</AlertTitle>
            {successMessage}
          </Alert>
        </Stack>
      )}
      {errorMessage && (
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert variant="outlined" severity="error">
          <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        </Stack>
      )}
      <div className="header-container">
        <div>
        Recherche par matricule:
          <Input type="text" onChange={handleFilter} />
        </div>
        <div>
          <AddNewUser />
        </div>
      </div>
      <div className="main">
        <DataTable
          customStyles={tableCustomStyles}
          defaultSortFieldId="ID"
          defaultSortAsc={false}
          pagination
          selectableRows
          fixedHeader
          columns={columns}
          data={records}
        />
      </div>
    </div>
  );
};

export default UsersList;
