import { AssignmentInd, ContactMail, Home } from "@mui/icons-material";
import MapIcon from "@mui/icons-material/Map";
import {
  Button,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper
} from "@mui/material";
import { jwtDecode } from 'jwt-decode';
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import img from "../../assets/logo-enda.jpg";
import "./SideBar.css";
interface MyJwtPayload {
  sub: string;
  Authorities: [
    {
      name: string;
    }
  ];
  matricule: number;
  exp: number;
}
const SideBar = () => {
  const navigate = useNavigate();
  const [role, setRole] = useState("");

  useEffect(()=> {
    const jwt = localStorage.getItem("token");
    if (jwt) {
      const decoded = jwtDecode<MyJwtPayload>(jwt || '') || null;
        console.log("token decodedddd : ",decoded.Authorities[0].name)
        setRole(decoded.Authorities[0].name)
    }
  },[])

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <Drawer  variant="permanent" anchor="left">
      <Paper className="drawer-content" style={{ backgroundColor: "#f9b719" }}>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "2rem",
            }}
          >

            <img src={img} style={{ width: "80px" }}  alt="alt"/>
          </div>
          <List className="list">
              <ListItemButton
                className="list-item"
                component={Link}
                to="/dashboard"
              >
                <ListItemIcon className="list-icon" sx={{ color: "#cc0066" }}>
                <Home />
                </ListItemIcon>
                <ListItemText
                  primary= "Home"
                  sx={{ color: "#cc0066" }}
                />
              </ListItemButton>
              <ListItemButton
                className="list-item"
                component={Link}
                to="/dashboard/users"
              >
                <ListItemIcon className="list-icon" sx={{ color: "#cc0066" }}>
                <AssignmentInd />
                </ListItemIcon>
                <ListItemText
                  primary= "Gestion des utilisateurs"
                  sx={{ color: "#cc0066" }}
                />
              </ListItemButton>
              {role !== "ROLE_SUPER_ADMIN" &&<ListItemButton
                className="list-item"
                component={Link}
                to="/dashboard/demandes"
              >
                <ListItemIcon className="list-icon" sx={{ color: "#cc0066" }}>
                <ContactMail />
                </ListItemIcon>
                <ListItemText
                  primary= "Gestion des demandes"
                  sx={{ color: "#cc0066" }}
                />
              </ListItemButton>}
              <ListItemButton
                className="list-item"
                component={Link}
                to="/dashboard/map"
              >
                <ListItemIcon className="list-icon" sx={{ color: "#cc0066" }}>
                <MapIcon />
                </ListItemIcon>
                <ListItemText
                  primary= "Map visualisation"
                  sx={{ color: "#cc0066" }}
                />
              </ListItemButton>
          </List>
        </div>
        <div className="logout-wrapper">
          <Button variant="contained" onClick={() => handleLogout()}>
          Déconnecter
          </Button>
        </div>
      </Paper>
    </Drawer>
  );
};

export default SideBar;
