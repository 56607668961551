import axios from 'axios';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useEffect, useState } from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { api_url } from '../../core/Config';

const MapVisualisation = () => {


  /*
  return (
    <div>Map Screen</div>
  )*/
  const [loading, setLoading] = useState(false);
  const [demandelist, setDemandelist] = useState([]);
  const [map, setMap] = useState<L.Map | null>(null);
  const pin = 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png';
  useEffect(() => {
    const fetchDemandes = async () => {
      try {
        const jwt = localStorage.getItem("token");
        const response = await axios.get(`${api_url}/demandes/alldemandes`, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });
        setDemandelist(response.data);
        setLoading(true);
      } catch (error) {
        alert(error);
        setLoading(false);
      }
    };

    fetchDemandes();
  }, []);
  const pinMB = L.icon({
    iconUrl: pin,
    iconSize: [18, 30],
    iconAnchor: [0, 44],
    popupAnchor: [12, -40],
  });
 

  return (
    <div>
      <MapContainer
        center={[34.0, 9.0]}
        zoom={6}
        style={{ height: '900px', width: '100%' }}
       // whenReady={() => setMap(map)}
     
        dragging={true}
        keyboard={true}
        scrollWheelZoom={true}
        attributionControl={true}
      >
          <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
         
         {demandelist.map((item: any) => (
          
  <Marker key={item.id} position={[item.longitude, item.latitude]} icon={pinMB}>
    <Popup>
    <h3 className="popup">code prospect:{item.id}</h3>
    <h4 className="popup">nom:{item.firstName} </h4>
    <h4 className="popup">prenom:{item.lastName} </h4>
    </Popup>
  </Marker>
  
))

}


         
      </MapContainer>
    </div>
  ); 
};

export default MapVisualisation;
