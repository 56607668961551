import { CSVLink } from 'react-csv';

const ExportDetails = ({ demandes }: any) => {
  const dateNow = new Date();

  // Fun :format dateDemande into separate date and time parts
  const formatDemandesForExport = (data: any[]) => {
    return data.map((demande: any) => {
      const dateDemande = new Date(demande.dateDemande);
      const formattedDate = dateDemande.toLocaleDateString('en-CA');
      const formattedTime = dateDemande.toLocaleTimeString('en-US', { hour12: false });
 
      return {
        ...demande,
        dateDemande: formattedDate,
        timeDemande: formattedTime
      };
    });
  };
console.log(demandes);
  // Format demandes for CSV export
  const formattedDemandes = formatDemandesForExport(demandes);

  // Define headers for CSV
  const headers = [
    { label: "Id prospect", key: "id" },
    { label: "Nom", key: "firstName" },
    { label: "Prenom", key: "lastName" },
    { label: "Telephone", key: "phoneNumber" },
    { label: "Description", key: "descriptionProjet" },
    { label: "Adresse", key: "adresseProjet" },
    { label: "Montant", key: "montantSouhaite" },
    { label: "Remarques et commentaires", key: "commentairesEtRemarques" },
    { label: "gouvernerat", key: "gouverneratGeoSector" },
    { label: "delegation", key: "delegationGeoSector" },
    { label: "ville", key: "ville" },
    { label: "secteur", key: "secteurGeoSector" },
    { label: "codePostale", key: "codePostale" },
    { label: "SecteurActivite", key: "nameSecteurActivite" },
    { label: "Activite", key: "nameActivite" },
    { label: "Besoins", key: "nameBesoinsClients" },
    { label: "Autres Besoins", key: "autreBesoins" },
    { label: "Date de demande", key: "dateDemande" },
    { label: "Heure de demande", key: "timeDemande" },
    { label: "Nom de l'utilisateur", key: "firstNameUser" },
    { label: "Prenom de l'utilisateur", key: "lastNameUser" },
    { label: "Matricule", key: "matriculeUser" },
    { label: "Longitude", key: "longitude" },
    { label: "Latitude", key: "latitude" }
  ];

  return (
    <div>
      <CSVLink data={formattedDemandes} headers={headers} separator=";" filename={`Demandes_${dateNow}`}>
        <button>Exporter des données</button>
      </CSVLink>
    </div>
  );
};

export default ExportDetails;
