import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import axios from "axios";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo-enda.jpg";
import { api_url } from "../../core/Config";
import { ChangePasswordType } from "../../helpers/types";
import { ChangePasswordSchema } from "../../helpers/validation";
import "./ChangePassword.css";
interface MyJwtPayload {
  sub: string;
  Authorities: [
    {
      name: string;
    }
  ];
  matricule: number;
  exp: number;
}
const ChangePassword = () => {
  const navigate = useNavigate();
  const initialValues: ChangePasswordType = {
    newPassword: "",
    confirmNewPassword: "",
  };
  const ChangePassword = async (values:any) => {
    const jwt = await localStorage.getItem("token");
    axios
    .post(
      `${api_url}/change-password`,
      {
        newPassword: values.newPassword,
      },
      {
        headers: {
          "Cache-Control": "no-cache",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${jwt}`,
        },
      }
    ) // no try/catch here
    .then((response) => { 
   
        navigate("/dashboard");
      
    })
    .catch((error) => {
      console.log("error here");
      console.log("error : ", error.response);
    });
  }
  return (
    <div className="wrapper">
      <div className="logo-wrapper">
        <img src={logo} style={{ width: "120px" }} alt="alt" />
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={ChangePasswordSchema}
        onSubmit={(values, { resetForm }) => {
          if (values.newPassword !== values.confirmNewPassword) {
            alert("Passwords do not match!");
            return; 
        }
          ChangePassword(values)
        }}
      >
        {({ errors, values, handleChange, handleSubmit, handleBlur }) => (
          <div
            style={{
              width: "400px",
              display: "flex",
              flexDirection: "column",
              gap: "3rem",
            }}
          >
            <div>
              <TextField
                style={{ width: "400px" }}
                id="newPassword"
                label="newPassword"
                variant="standard"
                type="password"
                value={values.newPassword}
                onChange={handleChange("newPassword")}
                onBlur={handleBlur("newPassword")}
              />
                  {errors.newPassword && (
                    <p style={{ color: "red" }}>{errors?.newPassword}</p>
                  )}
            </div>
            <div>
              <TextField
                style={{ width: "400px" }}
                id="confirmNewPassword"
                label="confirmNewPassword"
                variant="standard"
                type="password"
                value={values.confirmNewPassword}
                onChange={handleChange("confirmNewPassword")}
                onBlur={handleBlur("confirmNewPassword")}
              />
                 {errors.confirmNewPassword && (
                    <p style={{ color: "red" }}>{errors?.confirmNewPassword}</p>
                  )}
            </div>
            <div>
              <Button
                style={{ width: "400px" }}
                variant="contained"
                type="submit"
                onClick={() => handleSubmit()}
              >
               Enregistrer
              </Button>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default ChangePassword;
