import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { Formik } from "formik";
import * as React from "react";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { api_url } from "../../core/Config";
import { RegisterUser } from "../../helpers/types";
import { registerUserSchema } from "../../helpers/validation";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AddNewUser = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const initialValues: RegisterUser = {
    email: "",
    firstName: "",
    lastName: "",
    genre: 0,
    matricule: "",
    phoneNumber: "+216 ", 
  };
  return (
    <div>
      <Button
        style={{ width: "400px", color: "#cc0066", borderColor: "#cc0066" }}
        variant="outlined"
        onClick={handleOpen}
      >
        Ajouter un nouveau utilisateur
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Creation d'un nouveau utilisateur
          </Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={registerUserSchema}
            onSubmit={(values, { resetForm }) => {
              //submitForm(values);
              console.log("les valeurs user : ", values);
              const jwt = localStorage.getItem("token");
              console.log("jwt : ", jwt);
              axios
                .post(
                  `${api_url}/sign-up`,
                  {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    matricule: values.matricule,
                    phoneNumber: values.phoneNumber,
                    genre: values.genre
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${jwt}`,
                    },
                  }
                  
                ) // no try/catch here
                .then((response) => {
                  const data = response.data;
                  console.log("Response data:", data);
                  resetForm();
                  handleClose();
                  if (data && data.userAlreadyExists) { 
                    alert("Utilisateur existant");
                } else { 
                  alert("Nouvel utilisateur ajouté avec succès, ou activation réalisée avec succès");
                }
 
              })
              .catch((error) => {
                  console.log("Error:", error);
                  alert("Problème lors de l'ajout de l'utilisateur : l'utilisateur est déjà présent et activé");
              });
            }}
          >
            {({ errors, values, handleChange, handleSubmit, handleBlur }) => (
              <div
                style={{
                  width: "400px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <div>
                  <TextField
                    style={{ width: "400px" }}
                    id="firstName"
                    label="Nom"
                    variant="standard"
                    type="text"
                    value={values.firstName}
                    onChange={handleChange("firstName")}
                    onBlur={handleBlur("firstName")}
                  />
                  {errors.firstName && (
                    <p style={{ color: "red" }}>{errors?.firstName}</p>
                  )}
                </div>
                <div>
                  <TextField
                    style={{ width: "400px" }}
                    id="lastName"
                    label="Prénom"
                    variant="standard"
                    type="text"
                    value={values.lastName}
                    onChange={handleChange("lastName")}
                    onBlur={handleBlur("lastName")}
                  />
                  {errors.lastName && (
                    <p style={{ color: "red" }}>{errors?.lastName}</p>
                  )}
                </div>
                <div>
                  <TextField
                    style={{ width: "400px" }}
                    id="email"
                    label="Email"
                    variant="standard"
                    type="email"
                    value={values.email}
                    onChange={handleChange("email")}
                    onBlur={handleBlur("email")}
                  />
                  {errors.email && (
                    <p style={{ color: "red" }}>{errors?.email}</p>
                  )}
                </div>
                <div>
                  <TextField
                    style={{ width: "400px" }}
                    id="matricule"
                    label="Matricule"
                    variant="standard"
                    type="text"
                    value={values.matricule}
                    onChange={handleChange("matricule")}
                    onBlur={handleBlur("matricule")}
                  />
                  {errors.matricule && (
                    <p style={{ color: "red" }}>{errors?.matricule}</p>
                  )}
                </div>
                <div style={{ width: "400px" }}>
                  <FormLabel id="phone-input-label">Téléphone</FormLabel>
                  <div style={{ width: "400px" }}>
                    <PhoneInput
                      style={{ width: "400px" }}
                      defaultCountry="tn"
                      disableCountryGuess
                      inputProps={{
                        name: "phoneNumber",
                        required: true,
                        autoFocus: true,
                        placeholder: "Téléphone",
                        maxLength: 13,
                      }}
                      value={values.phoneNumber}
                      onChange={(value) => {
                        value = value.substring(0, 4) + ' ' + value.substring(4);
                        handleChange("phoneNumber")(value);
                      }}
                      onBlur={() => handleBlur("phoneNumber")}
                    />
                  </div>
                  {errors.phoneNumber && (
                    <p style={{ color: "red" }}>{errors?.phoneNumber}</p>
                  )}
                </div>
                <FormControl>
                  <FormLabel id="demo-controlled-radio-buttons-group">
                    Genre
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={values.genre}
                    onChange={handleChange("genre")}
                    onBlur={handleBlur("genre")}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Female"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="Male"
                    />
                  </RadioGroup>
                  {errors.genre && (
                    <p style={{ color: "red" }}>{errors?.genre}</p>
                  )}
                </FormControl>
                <div>
                  <Button
                    style={{ width: "400px" }}
                    variant="contained"
                    type="submit"
                    onClick={() =>{
                       handleSubmit();
                       
                       setTimeout(() => {
                        document.location.reload();
                      }, 3000);
                    }
                    }
                  >
                    Enregistrer
                  </Button>
                </div>
              </div>
            )}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
};

export default AddNewUser;
