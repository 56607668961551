import { Fullscreen } from "@mui/icons-material";
import { CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Input } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ExportDetails from "../../components/ExportDetails/ExportDetails";
import { api_url } from "../../core/Config";

// import DateRangePicker, { DateRange } from '@mui/lab/DateRangePicker';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import Box from '@mui/material/Box';
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: Fullscreen,
  bgcolor: "background.paper",
  border: "2px solid #000",
  p: 4,
};
const Demandes = () => {
  const [demandes, setDemandes] = useState([]);
  const [records, setRecords] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState(""); 
  const [selectedImageId, setSelectedImageId] = useState(null);
 
  

  const handleOpen = (id: any) => {
    setSelectedImageId(id);
    fetchImage(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setImageSrc("");
  };
  const fetchImage = async (id: any) => {
    try {
      const jwt = await localStorage.getItem("token");
      const response = await axios.get(`${api_url}/demandes/image/${id}`, {
        headers: { Authorization: `Bearer ${jwt}` },
        responseType: 'blob' // Use 'blob' to handle binary data in the browser
      });
  
      // Check response status and data
      if (response.status === 200 && response.data) {
        // Create a URL for the image
        const imageUrl = URL.createObjectURL(response.data);
        setImageSrc(imageUrl);
      } else {
        console.error("Failed to fetch image. Response status:", response.status);
      }
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  };
  
  useEffect(() => {
    const demandesList = async () => {
      const jwt = await localStorage.getItem("token");
      axios
        .get(
          `${api_url}/demandes/alldemandes`,

          {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          }
        )
        .then((response) => {
          setDemandes(response.data);
          setRecords(response.data);
          console.log("responses : ", response.data.dateDemande);
          setLoading(true);

        })
        .catch((error: any) => {
          alert(error);
          setLoading(false);

        });
    };

    demandesList();
  }, []);

  const tableCustomStyles = {
    headRow: {
      style: {
        color: "#223336",
        backgroundColor: "#e7eef0",
      },
    },
    rows: {
      style: {
        color: "white",
        backgroundColor: "#cc0066",
      },
      stripedStyle: {
        color: "NORMALCOLOR",
        backgroundColor: "NORMALCOLOR",
      },
    },
  };

  const columns = [
    {
      name: "Nom",
      selector: (row: any) => row.firstName,
    },
    {
      name: "Prenom",
      selector: (row: any) => row.lastName,
    },
    {
      name: "Telephone",
       selector: (row: any) => row.phoneNumber,
     //selector: (row: any) => row.phoneNumber.substring(4),
    },
    {
      name: "Description",
      selector: (row: any) => row.descriptionProjet,
    },
    {
      name: "Adresse",
      selector: (row: any) => row.adresseProjet,
    },
    {
      name: "Montant",
      selector: (row: any) => row.montantSouhaite,
    },
    {
      name: "Gouvernerat",
      selector: (row: any) => row.gouverneratGeoSector,
    },
    {
      name: "Délégation",
      selector: (row: any) => row.delegationGeoSector,
    },
    {
      name: "Secteur",
      selector: (row: any) => row.secteurGeoSector,
    },
    {
      name: "Ville",
      selector: (row: any) => row.ville,
    },
    {
      name: "Code Postale",
      selector: (row: any) => row.codePostale,
    },
    {
      name: "Activité",
      selector: (row: any) => row.nameActivite,
    },
    {
      name: "Secteur D'activité",
      selector: (row: any) => row.nameSecteurActivite,
    },
    {
      name: "Besoins",
      selector: (row: any) => row.nameBesoinsClients,
    },
    {
      name: "Autres Besoins",
      selector: (row: any) => row.autreBesoins,
    },
    {
      name: "Date de demande",
  //selector: (row: any) => row.dateDemande,
  
selector: (row: any) => {
      const date = new Date(row.dateDemande);
      console.log("the first one"+row.dateDemande);
      console.log("the second one"+date);
      return date.toLocaleString('fr-FR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });
    },
    },
    {
      name: "Matricule",
      selector: (row: any) => row.matriculeUser,
    },
    {
      name: "Longitude",
      selector: (row: any) => row.longitude,
    },
    {
      name: "Latitude",
      selector: (row: any) => row.latitude,
    },
 
    {
      name: "Photos",
      selector: (row: any) => (
        <div>
          <IconButton onClick={() => handleOpen(row.id)}>
            <Fullscreen fontSize="small" />
          </IconButton>
        </div>
      ),
    },
    {
      name: "google maps",
      selector: (row: any) => {
        return (
          <a
            href={`https://maps.google.com/?t=k&q=${row.longitude},${row.latitude}&z=5`}
            target="_blank"
          >
           Voir map
          </a>
        );
      },
    },
  ];

  async function handleFilter(event: any) {
    if (event.target.value !== "") {
      const newData = await records.filter((row: any) => {
        return row.matriculeUser
          .toLowerCase()
          .includes(event.target.value.toLowerCase());
      });
      const data = await newData.filter(function (props: any) {
      delete props.imageData1;
        return true;
        });
      setRecords(data);
    } else {
      const data = await demandes.filter(function (props: any) {
        delete props.imageData1;
          return true;
          });
      setRecords(data);
    }
  }
/*
  async function handleFilterDate(event: any) {
    if (event.target.value !== "") {
      const searchTerm = event.target.value.toLowerCase().trim();
      const filteredData = await demandes.filter((row: any) => {
        const rowDate = row.dateDemande.split('.')[0];
        return rowDate.toLowerCase().includes(searchTerm);
      });
      setRecords(filteredData);
    } else {
      setRecords(demandes);
    }
  }
  */
  async function handleFilterDate(event: any) {
    if (event.target.value !== "") {
      const searchTerm = event.target.value.trim();
      const filteredData = demandes.filter((row: any) => {
        const date = new Date(row.dateDemande);
        const formattedDate = `${('0' + date.getDate()).slice(-2)}/${('0' + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()}`;
        return formattedDate.includes(searchTerm);
      });
      setRecords(filteredData);
    } else {
      setRecords(demandes);
    }
  }
  
  

  return (
    <div >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: 'center',
          padding: '2rem'
        }}
      >
        <div>
        Recherche par matricule:
          <Input type="text" onChange={handleFilter}
              placeholder="Rechercher par matricule " />
        </div> <br></br>  <div>
        Recherche par date:
          <Input type="text" onChange={handleFilterDate}  placeholder=" dd/mm/yyyy" />
        </div>
        <ExportDetails demandes={records}/>
      </div>
      <div>
{loading === false?(
  <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
    <CircularProgress />
  </div>
):(

  <DataTable
  customStyles={tableCustomStyles}
  columns={columns}
  data={records}
  pagination
  />
  )
}
        </div>
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Image</DialogTitle>
        <DialogContent>
          {imageSrc ? (
            <img
              src={imageSrc}
              alt="Selected"
              style={{ width: '100%', height: 'auto' }}
            />
          ) : (
            <CircularProgress /> // Show a loader if imageSrc is empty
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
  
};

export default Demandes;
