
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PrivateRoute } from "../core/PrivateRoute";
import ChangePassword from "../pages/ChangePassword/ChangePassword";
import Dashboard from "../pages/Dashboard/Dashboard";
import Demandes from "../pages/Demandes/Demandes";
import Home from "../pages/Home/Home";
import Login from "../pages/Login/Login";
import MapVisualisation from "../pages/Map/MapVisualisation";
import Users from "../pages/Users/Users";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" Component={Login} />
        <Route path="/change-password" Component={ChangePassword} />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        >
          <Route path="/dashboard" Component={Home} />
          <Route path="/dashboard/users" Component={Users} />
          <Route path="/dashboard/map" Component={MapVisualisation} />
          <Route path="/dashboard/demandes" Component={Demandes} /> 
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
