import UsersList from "../../components/UsersList/UsersList";

const Users = () => {

  return (
    <div className="container">
      <div>
      

      </div>
      
      <div>
      <UsersList />

      </div>
    </div>
  );
};

export default Users;
