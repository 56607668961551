import { Navigate } from "react-router-dom";

export const PrivateRoute = ({ children }:any) => {

    const isAuthenticated = localStorage.getItem("token");
    console.log("jwt : ", isAuthenticated);
        
    if (isAuthenticated ) {
      return children
    }
      
    return <Navigate to="/" />
  }