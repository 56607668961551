import Router from '../routes/Router'

const Layout = () => {
  return (
    <div className='layout'>
        <div className='main_layout'>
                <div className='content'>
                    <Router />
                </div>
        </div>
    </div>
  )
}

export default Layout