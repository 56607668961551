import * as Yup from "yup";

export const loginSchema = Yup.object().shape({
    username: Yup.string() .matches(/^(?:[a-zA-Z0-9._%+-]+@(?:gmail\.com|gmail\.tn|endatamweel\.com|endatamweel\.tn))$/,"Veuillez fournir une adresse e-mail valide ")
    .email("email format required !")
    .required("Merci d’insérer un email"),
    password: Yup.string().required("Merci d’insérer votre mot de passe"),
  });

export const registerUserSchema = Yup.object().shape({
  firstName: Yup.string().required("Merci d’insérer le nom")
  .matches(/^[aA-zZ\s]+$/, "Seuls les alphabets sont autorisés pour ce champ").max(20, 'maximum vingt caractères').min(3,'minimum trois caractères'),
  lastName: Yup.string().required("Merci d’insérer le prénom ")
    .matches(/^[aA-zZ\s]+$/, "Seuls les alphabets sont autorisés pour ce champ").max(20, 'maximum vingt caractères').min(3,'minimum trois caractères'),
    email: Yup.string()
    .matches(/^(?:[a-zA-Z0-9._%+-]+@(?:gmail\.com|gmail\.tn|endatamweel\.com|endatamweel\.tn))$/,"Veuillez fournir une adresse e-mail valide ")
    .email("email format required !")
    .required("Merci d’insérer un email"),
  phoneNumber: Yup.string()
  .required("Merci d'insérer le numéro")
  .matches(
    /^(\+?216)?\s?\d{2}\s?\d{3}\s?\d{3}$/,
    "Merci d’insérer le numéro de téléphone"
  )
  .matches(
    /^(\+?216)?\s?(2[0-9]|5[0-9]|9[0-9])\d{6}$/,
    "Le numéro de téléphone n'est pas correct"
  ),
  matricule: Yup.string().matches(/^[A-Z]{1}\d{4}$/, 'La matricule est invalide')
  .required("Merci d'insérer la matricule")
});

export const ChangePasswordSchema = Yup.object().shape({
  newPassword: Yup.string().required("Merci d’insérer votre mot de passe"),
  confirmPassword: Yup.string()
  .oneOf([Yup.ref('newPassword')], 'Merci de confirmer votre mot de passe')
})