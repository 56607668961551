import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import axios from "axios";
import { Formik } from "formik";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo-enda.jpg";
import { api_url } from "../../core/Config";
import { LoginType } from "../../helpers/types";
import { loginSchema } from "../../helpers/validation";
import "./Login.css";
interface MyJwtPayload {
  sub: string;
  Authorities: [
    {
      name: string;
    }
  ];
  matricule: number;
  exp: number;
}
const Login = () => {
  const navigate = useNavigate();
  const initialValues: LoginType = {
    username: "",
    password: "",
  };

  return (
    <div className="login-wrapper">
      <div className="logo-wrapper">
        <img src={logo} style={{ width: "120px" }} alt="alt"/>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={loginSchema}
        onSubmit={(values, { resetForm }) => {
          axios
            .post(
              `${api_url}/authenticate`,
              {
                username: values.username,
                password: values.password,
              },
              {
                headers: {
                  "Cache-Control": "no-cache",
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                  withCredentials: false,
                },
              }
            ) // no try/catch here
            .then((response) => {
              console.log("Hello Token", response.data);
              const { jwt } = response.data;
              const { flag } = response.data;
              
              localStorage.setItem("token", jwt)
              const decoded = jwtDecode<MyJwtPayload>(jwt || "") || null;
             // console.log("fllllllllllllllllllllllllllllllag", flag);
            //  console.log("JWtttttttttt", jwt);
             // console.log("JWtttttttttt", decoded.Authorities[0].name); 
              try {
                if (decoded.Authorities[0].name === 'ROLE_ADMIN' || decoded.Authorities[0].name === 'ROLE_SUPER_ADMIN') {
                 if (flag === 0) {
                    
                    navigate("/change-password");
                  } else {
                    navigate("/dashboard");
                  }
                 // navigate("/change-password");
                } else {
                  throw new Error("You don't have permission to access this page.");
                }
              } catch (error: any) {
                // Handle the error
                alert(error.message);
              }
              
              
              // if (
              //   jwt &&
              //   flag === 0 &&
              //   decoded.Authorities[0].name === "ROLE_ADMIN"
              // ) {
              //   navigate("/change-password");
              // } else {
              //   navigate("/dashboard");
              // }
            })
            .catch((error) => {
              console.log("error here");
              console.log("error : ", error.response);
              if (error.response && error.response.status === 401) {
                alert("Invalid username or password. Please try again.");
              } else {
                alert("An error occurred. Please check your credentials and try again.");
              }
            });
        }}
      >
        {({ errors, values, handleChange, handleSubmit, handleBlur }) => (
          <div
            style={{
              width: "400px",
              display: "flex",
              flexDirection: "column",
              gap: "3rem",
            }}
          >
            <div>
              <TextField
                style={{ width: "400px" }}
                id="username"
                label="Email"
                variant="standard"
                value={values.username}
                onChange={handleChange("username")}
                onBlur={handleBlur("username")}
              />
               {errors.username && (
                    <p style={{ color: "red" }}>{errors?.username}</p>
                  )}
            </div>
            <div>
              <TextField
                style={{ width: "400px" }}
                id="password"
                label="Password"
                variant="standard"
                type="password"
                value={values.password}
                onChange={handleChange("password")}
                onBlur={handleBlur("password")}
              />
                  {errors.password && (
                    <p style={{ color: "red" }}>{errors?.password}</p>
                  )}
            </div>
            <div>
              <Button
                style={{ width: "400px" }}
                variant="contained"
                type="submit"
                onClick={() => handleSubmit()}
              >
             Se Connecter
              </Button>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default Login;
